import { numberToCommaSeparatedString } from '@/utils/number'

const StatRate = ({
  value,
  zeroString,
}: {
  value: number
  zeroString?: string
}) => {
  return (
    <div className="flex flex-col justify-center text-primary">
      <p className="material-icons mb-3">play_arrow</p>
      <p className="text-xs font-bold">
        {numberToCommaSeparatedString(value, zeroString)} %
      </p>
    </div>
  )
}

export default StatRate
