import { Base, getConverter } from './document'

export interface Thumbnails {
  active: 'default' | 'autoGif'
  default: Thumbnail
  autoGif?: Thumbnail
}

export interface Thumbnail {
  height?: number
  url: string
  isGenerating?: boolean
  width?: number
}

export interface Chapter {
  label?: string
  time?: number
}

export type Provider = 'youtube' | 'file'

/**
 * Firestore Document Content Interface
 * @typeparam {T} set timestamp type parameter
 * @typeparam {U} set DocumentReference type parameter
 * */
export interface Content<T = any, U = any> extends Base<T, U> {
  videoUrl?: string // MEMO: when backend is uploading to aws, there is no "videoUrl".
  thumbnails: Thumbnails
  isProcessing: boolean
  duration: number
  title: string
  description: string
  creator: string
  provider: Provider
  s3ObjectKey?: string
  chapters: Chapter[]
}

export const contentConverter = getConverter<Content>((snapshot, data) => {
  const content: Content = {
    id: snapshot.id,
    ref: snapshot.ref,
    ...data,
    isProcessing: data.isProcessing || false,
    chapters: data.chapters || [],
    thumbnails: {
      ...data.thumbnails,
      active: data.thumbnails.active || 'default',
    },
  }
  return content
})
