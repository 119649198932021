import { FC, useMemo } from 'react'
import { Behavior } from 'vidbase-types/behavior'
import { View } from 'vidbase-types/view'
import StatBox from '@/components/shared/dashboard/Stat/StatBox'
import StatRate from '@/components/shared/dashboard/Stat/StatRate'
import { getImpression } from '@/services/behavior'

type Props = {
  behaviors: Behavior[]
  views: View[]
  isLoading: boolean
}

const Stat: FC<Props> = (props) => {
  const { behaviors, views, isLoading } = props
  const { impression, play, ctaImpression, conversion } = createAnalyticsData(
    behaviors,
    views
  )

  const zeroString = useMemo(() => {
    return isLoading ? '-' : '0'
  }, [isLoading])

  return (
    <div className="m-auto mb-20 flex w-full justify-between px-10 py-8">
      {/* FIXME: As "visit", "ready" and "play" bahaviors are not created at a VidbasePlayer, show "-" as value. */}
      {/* <StatBox title="表示" value={impression} /> */}
      <StatBox title="表示" value={impression}>
        <div className="typography-c1m text-base-400">表示</div>
        <div className="typography-h1 text-base-400">-</div>
      </StatBox>
      {/* FIXME: As "visit", "ready" and "play" bahaviors are not created at a VidbasePlayer, show "-" as value. */}
      {/* <StatRate value={isLoading ? 0 : roundAnalyticsData(play / impression)} /> */}
      <StatRate value={0} zeroString="-" />
      <StatBox title="再生" value={play} />
      <StatRate
        value={isLoading ? 0 : roundAnalyticsData(ctaImpression / impression)}
        zeroString={zeroString}
      />
      <StatBox title="CTA表示" value={ctaImpression} />
      <StatRate
        value={isLoading ? 0 : roundAnalyticsData(conversion / impression)}
        zeroString={zeroString}
      />
      <StatBox title="コンバージョン" value={conversion} />
    </div>
  )
}

export default Stat

const createAnalyticsData = (behaviors: Behavior[], views: View[]) => {
  return {
    impression: getImpression(behaviors),
    play: views.length,
    ctaImpression: sumCtaImpression(views),
    conversion: sumConversion(views),
  }
}

const sumCtaImpression = (views: View[]) => {
  return views.reduce(
    (prev, view) => prev + view.actionButtonShowCounts + view.formShowCounts,
    0
  )
}

const sumConversion = (views: View[]) => {
  return views.reduce(
    (prev, view) => prev + view.actionClickCounts + view.formSubmittedCounts,
    0
  )
}

const roundAnalyticsData = (data: number) => {
  return Math.round(data * 1000) / 10
}
