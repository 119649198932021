/**
 * This functions converts num (number) to a comma separated numerical string
 * @param num
 * @param zeroString default: "0" optional
 * @returns comma separated numerical string
 */

export const numberToCommaSeparatedString = (num: number, zeroString = '0') => {
  if (num === 0 || num === undefined || isNaN(num)) {
    return zeroString
  }

  return num.toLocaleString()
}
