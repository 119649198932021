import {
  DocumentReference,
  orderBy,
  query,
  QueryConstraint,
  Timestamp,
} from 'firebase/firestore'
import { useState } from 'react'
import { Content, contentConverter } from 'vidbase-types/content'
import { getContentsRef } from '@/repositories/content'
import { useFirestoreSubscribe } from '@/hooks/common/useFirestoreSubscribe'

const useContentsSubscribe = (
  workspaceId: string | undefined,
  ...queryConstraints: QueryConstraint[]
): [Content<Timestamp, DocumentReference>[], boolean, Error | undefined] => {
  const [contents, setContents] = useState<
    Content<Timestamp, DocumentReference>[]
  >([])

  const { loading, error } = useFirestoreSubscribe(
    {
      queryRefFn: () => {
        if (!workspaceId) {
          return
        }

        const contentsRef = getContentsRef(workspaceId)
        const fetchQuery = queryConstraints
          ? query(contentsRef, ...queryConstraints)
          : query(contentsRef, orderBy('createdAt', 'desc'))
        return fetchQuery.withConverter<Content<Timestamp, DocumentReference>>(
          contentConverter
        )
      },
      queryObserver: (snapshot) => {
        const value = snapshot.docs.map((doc) => doc.data())
        setContents(value)
      },
    },
    [workspaceId]
  )

  return [contents, loading, error]
}

export default useContentsSubscribe
