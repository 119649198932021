import sortBy from 'lodash/sortBy'
import Link from 'next/link'
import { FC } from 'react'
import { Behavior } from 'vidbase-types/behavior'
import { Content } from 'vidbase-types/content'
import { View } from 'vidbase-types/view'
import { contentThumbnail } from 'vidbase-utils/vidbase/content'
import Loader from 'vidbase-ui/components/feedback/loader'
import useContentsSubscribe from '@/hooks/content/useContentsSubscribe'
import { getImpression } from '@/services/behavior'

type Props = {
  behaviors: Behavior[]
  views: View[]
  workspaceId: string | undefined
}

const MovingList: FC<Props> = (props) => {
  const { behaviors, views, workspaceId } = props
  const [contents, isContentsLoading] = useContentsSubscribe(workspaceId)
  const sortContents = sortContentsByImpression(contents, behaviors)

  return (
    <table className="table w-full">
      <thead>
        <tr className="text-center">
          <td className="text-left">動画</td>
          <td className="text-base-400">表示</td>
          <td>再生</td>
          <td>CTA表示</td>
          <td>コンバージョン</td>
        </tr>
      </thead>

      <tbody className="text-center">
        {isContentsLoading && (
          <Loader.BlockRoot>
            <Loader />
          </Loader.BlockRoot>
        )}
        {sortContents.map((c) => {
          const { content } = c
          const tableData = createTableData(content.id, views)
          return (
            <tr
              className="border border-base-200 text-sm font-normal"
              key={content.id}
            >
              <td className="w-[42%]">
                <div className="flex items-center space-x-3">
                  <Link href={`/contents/${content.id}`}>
                    <div
                      className="h-[80px] w-[142px] rounded bg-cover bg-no-repeat ease-out hover:opacity-50"
                      style={{
                        backgroundImage: `url(${contentThumbnail(content)})`,
                      }}
                    />
                  </Link>
                  <div className="text-left font-medium hover:text-primary">
                    <Link
                      href={`/contents/${content.id}`}
                      className="line-clamp-2 whitespace-normal"
                    >
                      {content.title}
                    </Link>
                  </div>
                </div>
              </td>
              {/* FIXME: As "visit", "ready" and "play" bahaviors are not created at a VidbasePlayer, show "-" as value. */}
              {/* <td className="w-[14.5%] truncate text-base-400">{impression}</td> */}
              <td className="w-[14.5%] truncate text-base-400">-</td>
              <td className="w-[14.5%] truncate">{tableData.play}</td>
              <td className="w-[14.5%] truncate">{tableData.ctaImpression}</td>
              <td className="w-[14.5%] truncate">{tableData.conversion}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default MovingList

const createTableData = (id: string, views: View[]) => {
  return {
    play: sumPlay(id, views),
    ctaImpression: sumCtaImpression(id, views),
    conversion: sumConversion(id, views),
  }
}

const filterViewsBySameContent = (id: string, views: View[]) => {
  return views.filter((view) => id === view.contentId)
}

const addImpressionsToContent = (content: Content, behaviors: Behavior[]) => {
  const impression = getImpression(
    behaviors.filter((behavior) => behavior.contentId === content.id)
  )
  return { content, impression }
}

const sortContentsByImpression = (
  contents: Content[],
  behaviors: Behavior[]
) => {
  const contentsWithImpressions = contents.map((content) =>
    addImpressionsToContent(content, behaviors)
  )
  return sortBy(contentsWithImpressions, 'impression').reverse()
}

const sumPlay = (id: string, views: View[]): number => {
  return views.filter((view) => id === view.contentId).length
}

const sumCtaImpression = (id: string, views: View[]): number => {
  const contentViewData = filterViewsBySameContent(id, views)
  return contentViewData.reduce(
    (prev, view) => prev + view.actionButtonShowCounts + view.formShowCounts,
    0
  )
}

const sumConversion = (id: string, views: View[]): number => {
  const contentViewData = filterViewsBySameContent(id, views)
  return contentViewData.reduce(
    (prev, view) => prev + view.actionClickCounts + view.formSubmittedCounts,
    0
  )
}
