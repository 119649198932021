import { Base, getConverter } from './document'

/**
 * Firestore Document View Interface
 * @typeparam {T} set timestamp type parameter
 * @typeparam {U} set DocumentReference type parameter
 * */
export interface View<T = any, U = any> extends Base<T, U> {
  viewSeconds: number[]
  totalViewTime: number
  workspaceId: string

  contentId?: string
  embedId?: string
  campaignId?: string
  contactId?: string
  scheduleId?: string
  webinarId?: string
  portalId?: string
  videoId?: string

  crmCampaignId?: string
  referrer?: string | null
  actionButtonShowCounts: number
  actionClickCounts: number
  formShowCounts: number
  formSubmittedCounts: number

  marketoLeadId?: string
  munchkinAccountId?: string

  pardotBusinessUnitId?: string
  pardotProspectId?: string
  pardotProspectAccountId?: string

  customUserId?: string
}

export const viewConverter = getConverter<View>((snapshot, data) => {
  const view: View = {
    id: snapshot.id,
    ref: snapshot.ref,
    ...data,
    actionButtonShowCounts: data.actionButtonShowCounts || 0,
    actionClickCounts: data.actionClickCounts || 0,
    formShowCounts: data.formShowCounts || 0,
    formSubmittedCounts: data.formSubmittedCounts || 0,
  }
  return view
})

export interface CampaignView
  extends Omit<
    View,
    | 'contentId'
    | 'embedId'
    | 'campaignId'
    | 'contactId'
    | 'scheduleId'
    | 'webinarId'
    | 'portalId'
    | 'videoId'
    | 'referrer'
  > {
  contentId: string
  campaignId: string
}

export interface EmbedView
  extends Omit<
    View,
    | 'contentId'
    | 'embedId'
    | 'campaignId'
    | 'contactId'
    | 'scheduleId'
    | 'webinarId'
    | 'portalId'
    | 'videoId'
    | 'referrer'
  > {
  contentId: string
  embedId: string
  referrer: string | null
}

export interface ScheduleView
  extends Omit<
    View,
    | 'contentId'
    | 'embedId'
    | 'campaignId'
    | 'contactId'
    | 'scheduleId'
    | 'webinarId'
    | 'portalId'
    | 'videoId'
    | 'referrer'
  > {
  contentId: string
  scheduleId: string
}

export interface WebinarView
  extends Omit<
    View,
    | 'contentId'
    | 'embedId'
    | 'campaignId'
    | 'contactId'
    | 'scheduleId'
    | 'webinarId'
    | 'portalId'
    | 'videoId'
    | 'referrer'
  > {
  webinarId: string
}

export interface ContactView
  extends Omit<
    View,
    | 'contentId'
    | 'embedId'
    | 'campaignId'
    | 'contactId'
    | 'scheduleId'
    | 'webinarId'
    | 'portalId'
    | 'videoId'
    | 'referrer'
    | 'marketoLeadId'
    | 'munchkinAccountId'
    | 'pardotBusinessUnitId'
    | 'pardotProspectId'
    | 'pardotProspectAccountId'
    | 'actionButtonShowCounts'
    | 'actionClickCounts'
    | 'formShowCounts'
    | 'formSubmittedCounts'
  > {
  contentId: string
  contactId: string
}

export interface PortalView
  extends Omit<
    View,
    | 'contentId'
    | 'embedId'
    | 'campaignId'
    | 'contactId'
    | 'scheduleId'
    | 'webinarId'
    | 'portalId'
    | 'videoId'
    | 'referrer'
  > {
  contentId: string
  portalId: string
  videoId: string
}
