import { numberToCommaSeparatedString } from '@/utils/number'

const StatBox = ({
  title,
  value,
  children,
}: {
  title: string
  value: number
  children?: React.ReactNode
}) => {
  return (
    <div className="w-[140px] rounded-2xl border py-4 pl-5 shadow-md">
      {children ? (
        children
      ) : (
        <>
          <div className="typography-c1m">{title}</div>
          <div className="typography-h1">
            {numberToCommaSeparatedString(value)}
          </div>
        </>
      )}
    </div>
  )
}

export default StatBox
