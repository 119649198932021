import {
  DocumentReference,
  query,
  QueryConstraint,
  Timestamp,
} from 'firebase/firestore'
import { useState } from 'react'
import { Behavior, behaviorConverter } from 'vidbase-types/behavior'
import { getWorkspaceBehaviorsQuery } from '@/repositories/behavior'
import { useFirestoreSubscribe } from '@/hooks/common/useFirestoreSubscribe'

const useBehaviorSubscribe = (
  workspaceId: string | undefined,
  ...queryConstraints: QueryConstraint[]
): [Behavior<Timestamp, DocumentReference>[], boolean, Error | undefined] => {
  const [behaviors, setBehaviors] = useState<
    Behavior<Timestamp, DocumentReference>[]
  >([])

  const { loading, error } = useFirestoreSubscribe(
    {
      queryRefFn: () => {
        if (!workspaceId) {
          return
        }

        const baseQuery = getWorkspaceBehaviorsQuery(workspaceId)
        const fetchQuery = queryConstraints
          ? query(baseQuery, ...queryConstraints)
          : baseQuery

        return fetchQuery.withConverter<Behavior<Timestamp, DocumentReference>>(
          behaviorConverter
        )
      },
      queryObserver: (snap) => {
        const value = snap.docs.map((doc) => doc.data())
        setBehaviors(value)
      },
    },
    [workspaceId]
  )

  return [behaviors, loading, error]
}

export default useBehaviorSubscribe
