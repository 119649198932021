import {
  addDays,
  differenceInDays,
  endOfToday,
  format,
  isSameDay,
} from 'date-fns'
import { FC } from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { Behavior } from 'vidbase-types/behavior'
import { View } from 'vidbase-types/view'
import { getImpression } from '@/services/behavior'

type ChartData = {
  date: string
  impression: number
  play: number
  ctaImpression: number
  conversion: number
}

type Props = {
  behaviors: Behavior[]
  views: View[]
  startedDate: Date
  endedDate?: Date
}

const Chart: FC<Props> = ({
  behaviors,
  views,
  startedDate,
  endedDate = endOfToday(),
}) => {
  const { chartData, maxImpression } = createChartDate(
    behaviors,
    views,
    startedDate,
    endedDate
  )
  const xTicks = calculateXTicks(startedDate, endedDate)
  // Round up to the nearest number by 100
  const topYTick = Math.ceil(maxImpression / 100) * 100
  const yTicks = calculateYTicks(views, topYTick)
  return (
    <ResponsiveContainer width={'100%'} height={'100%'}>
      <AreaChart
        data={chartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="impression" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3199C3" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#3199C3" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="play" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#14b8a6" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#14b8a6" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="ctaImpression" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#84cc16" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#84cc16" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="conversion" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#eab308" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#eab308" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          ticks={xTicks}
          domain={[formatDate(startedDate), formatDate(endedDate)]}
          dataKey="date"
        />
        <YAxis
          ticks={yTicks}
          type="number"
          domain={[0, topYTick]}
          dataKey="impression"
        />
        <Tooltip />
        <XAxis
          ticks={xTicks}
          domain={[formatDate(startedDate), formatDate(endedDate)]}
          dataKey="date"
        />
        <YAxis
          ticks={yTicks}
          type="number"
          domain={[0, topYTick]}
          dataKey="impression"
        />
        <Tooltip />
        <Area
          name="表示"
          type="monotone"
          dataKey="impression"
          stackId="1"
          stroke="#3199C3"
          fill="url(#impression)"
        />
        <Area
          name="再生"
          type="monotone"
          dataKey="play"
          stackId="2"
          stroke="#14b8a6"
          fill="url(#play)"
        />
        <Area
          name="CTA表示"
          type="monotone"
          dataKey="ctaImpression"
          stackId="3"
          stroke="#84cc16"
          fill="url(#ctaImpression)"
        />
        <Area
          name="コンバージョン"
          type="monotone"
          dataKey="conversion"
          stackId="4"
          stroke="#eab308"
          fill="url(#conversion)"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default Chart

const formatDate = (date: Date): string => {
  return format(date, 'M/d')
}

const createChartDate = (
  behaviors: Behavior[],
  views: View[],
  startedDate: Date,
  endedDate: Date
): {
  chartData: ChartData[]
  maxImpression: number
} => {
  const diff = differenceInDays(endedDate, startedDate)
  const chartData: ChartData[] = []
  let maxImpression = 0
  for (let i = 0; i <= diff; i++) {
    const date = addDays(startedDate, i)
    const viewsOfDate = views.filter((view) => {
      const createdDate = view?.createdAt?.toDate()
      if (!createdDate) return false
      return isSameDay(createdDate, date)
    })
    const behaviorsOfDate = behaviors.filter((behavior) => {
      const createdDate = behavior?.createdAt?.toDate()
      if (!createdDate) return false
      return isSameDay(createdDate, date)
    })
    const impression = getImpression(behaviorsOfDate)
    chartData.push({
      date: formatDate(date),
      impression: impression,
      play: viewsOfDate.length,
      ctaImpression: sumCtaImpression(viewsOfDate),
      conversion: sumConversion(viewsOfDate),
    })
    if (maxImpression < impression) {
      maxImpression = impression
    }
  }
  return {
    chartData,
    maxImpression,
  }
}

const sumCtaImpression = (views: View[]): number => {
  return views.reduce(
    (prev, view) => prev + view.actionButtonShowCounts + view.formShowCounts,
    0
  )
}

const sumConversion = (views: View[]): number => {
  return views.reduce(
    (prev, view) => prev + view.actionClickCounts + view.formSubmittedCounts,
    0
  )
}

const X_TICK_COUNT = 7
const calculateXTicks = (startedDate: Date, endedDate: Date): string[] => {
  const diff = differenceInDays(endedDate, startedDate)
  const interval = Math.round(diff / (X_TICK_COUNT - 1))
  return [...Array(X_TICK_COUNT)].map((_, i) => {
    if (i === 0) return formatDate(startedDate)
    if (i === X_TICK_COUNT - 1) return formatDate(endedDate)
    return formatDate(addDays(startedDate, i * interval))
  })
}

const Y_TICK_COUNT = 5
const calculateYTicks = (views: View[], topYTick: number): number[] => {
  const interval = Math.round(topYTick / (Y_TICK_COUNT - 1))
  return [...Array(Y_TICK_COUNT)].map((_, i) => {
    if (i === 0) return 0
    if (i === Y_TICK_COUNT - 1) return topYTick
    return i * interval
  })
}
