import { startOfToday, subDays } from 'date-fns'
import { startAt, where, orderBy } from 'firebase/firestore'
import { useMemo } from 'react'
import Loader from 'vidbase-ui/components/feedback/loader'
import AppGuard from '@/components/shared/appGuard'
import Chart from '@/components/shared/dashboard/Chart'
import MovingList from '@/components/shared/dashboard/MovieList'
import Stat from '@/components/shared/dashboard/Stat'
import BaseLayout from '@/components/shared/layouts/baseLayout'
import useBehaviorSubscribe from '@/hooks/behavior/useBehaviorSubscribe'
import useViewsSubscribe from '@/hooks/view/useViewsSubscribe'
import useWorkspace from '@/hooks/workspace/useWorkspace'
import { NextPageWithLayout } from '@/types/nextPage'

const Page: NextPageWithLayout = () => {
  const [workspace] = useWorkspace()
  const thirtyDaysAgo = subDays(startOfToday(), 30)
  const [views, isViewsLoading] = useViewsSubscribe(
    workspace?.id,
    orderBy('createdAt', 'asc'),
    startAt(thirtyDaysAgo)
  )
  const [behaviors, isBehaviorsLoading] = useBehaviorSubscribe(
    workspace?.id,
    where('behaviorType', '==', 'ready'),
    orderBy('createdAt', 'asc'),
    startAt(thirtyDaysAgo)
  )

  const isLoading = useMemo(() => {
    return isViewsLoading || isBehaviorsLoading
  }, [isBehaviorsLoading, isViewsLoading])

  return (
    <BaseLayout>
      <div className="w-full px-6 py-4">
        <div className="mb-2.5 flex justify-between">
          <h2 className="flex items-center text-lg font-bold text-base-content">
            <span className="material-icons mr-3 text-natural opacity-70">
              show_chart
            </span>
            ダッシュボード
          </h2>
          <select className="select-bordered select max-w-[160px] font-normal">
            <option selected>過去30日間</option>
          </select>
        </div>
        <div className="rounded-md bg-white py-5 pl-8 pr-4">
          <div className="mb-10 text-lg font-bold text-base-content">
            視聴ファネル
          </div>
          <div className="mb-10 h-[450px] min-w-[600px]">
            {isLoading ? (
              <div className="flex-column flex h-full items-center">
                <Loader.BlockRoot>
                  <Loader />
                </Loader.BlockRoot>
              </div>
            ) : (
              <Chart
                behaviors={behaviors}
                views={views}
                startedDate={thirtyDaysAgo}
              />
            )}
          </div>
          <Stat behaviors={behaviors} views={views} isLoading={isLoading} />
          <div className="mb-10 text-lg font-bold text-base-content">
            動画リスト
          </div>
          <div className="w-full overflow-x-auto">
            {isLoading ? (
              <Loader.BlockRoot>
                <Loader />
              </Loader.BlockRoot>
            ) : (
              <MovingList
                behaviors={behaviors}
                views={views}
                workspaceId={workspace?.id}
              />
            )}
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

Page.getLayout = (page) => <AppGuard>{page}</AppGuard>

export default Page
