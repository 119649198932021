import {
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  Query,
  QuerySnapshot,
  SnapshotListenOptions,
  Unsubscribe,
  onSnapshot,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/nextjs'

export interface SubscriptionInput<T = unknown> {
  queryRefFn(): Query<T> | DocumentReference<T> | undefined
  queryObserver?: (snap: QuerySnapshot<T>) => void
  docObserver?: (snap: DocumentSnapshot<T>) => void
  /**
   * We do have a default handler for error, but if your really need to
   * custom it please use onError callback
   * @param error Firebase error
   * @returns void
   */
  onError?: (error: FirestoreError) => void
  onComplete?: () => void
  fetchOptions?: SnapshotListenOptions
}

/**
 * Use to subscribe firestore db change when it get updated, build up on `onSnapshot` of firebase, it will automatically
 * unsubscribe whenever the component unmounts
 * @param subInput - Params contains `queryRef`, `observer` callback and optional callbacks such as
 * @param deps - dependencies that update the subscription
 * @returns `error` - an FirebaseError that can be used on UI
 * @returns `loading` - Loading state that can be used on UI
 */
export const useFirestoreSubscribe = <T = unknown>(
  subInput: SubscriptionInput<T>,
  deps: any[]
) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>(undefined)
  const {
    queryRefFn,
    queryObserver,
    docObserver,
    onError,
    onComplete,
    fetchOptions = {},
  } = subInput

  if (!queryObserver && !docObserver) {
    throw Error('Please provide at least one observer')
  }

  const defaultErrorHandler = (err: FirestoreError) => {
    setError(err)
    onError?.(err)
    setLoading(false)
    sendSentryError(err, queryRefFn())
    console.info('Error while subscribing firestore snapshot', err)
  }

  useEffect(() => {
    const queryRef = queryRefFn()
    if (!queryRef) {
      return
    }

    let unSub: Unsubscribe
    if (queryRef instanceof Query) {
      // Use for query ref
      unSub = onSnapshot(
        queryRef,
        fetchOptions,
        (snpShot) => {
          queryObserver?.(snpShot)
          setLoading(false)
        },
        defaultErrorHandler,
        onComplete
      )
    } else if (queryRef instanceof DocumentReference) {
      // Use for document ref
      unSub = onSnapshot(
        queryRef,
        fetchOptions,
        (snpShot) => {
          docObserver?.(snpShot)
          setLoading(false)
        },
        defaultErrorHandler,
        onComplete
      )
    }

    return () => unSub()
    // We only need to subscribe when the component did mount
    // If we pass all object dependencies it will rerender and cause bug
    /* eslint-disable react-hooks/exhaustive-deps */
  }, deps)

  return { error, loading }
}

const sendSentryError = (err: FirestoreError, queryRef: any) => {
  Sentry.captureException(err, {
    contexts: {
      subscribe: {
        query: JSON.stringify(queryRef),
      },
    },
  })
}
