import {
  collectionGroup,
  where,
  query,
  QueryConstraint,
} from 'firebase/firestore'
import { db } from '@/services/firebase/client'

export const getBehaviorsQuery = () => collectionGroup(db, 'behaviors')

export const getWorkspaceBehaviorsQuery = (workspaceId: string) => {
  const q = getBehaviorsQuery()
  const queryConstraints: QueryConstraint[] = [
    where('workspaceId', '==', workspaceId),
  ]

  const getQuery = query(q, ...queryConstraints)
  return getQuery
}
