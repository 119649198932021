import {
  DocumentReference,
  query,
  QueryConstraint,
  Timestamp,
} from 'firebase/firestore'
import { useState } from 'react'
import { View, viewConverter } from 'vidbase-types/view'
import { getWorkspaceViewsQuery } from '@/repositories/view'
import { useFirestoreSubscribe } from '@/hooks/common/useFirestoreSubscribe'

const useViewsSubscribe = (
  workspaceId: string | undefined,
  ...queryConstraints: QueryConstraint[]
): [View<Timestamp, DocumentReference>[], boolean, Error | undefined] => {
  const [views, setViews] = useState<View<Timestamp, DocumentReference>[]>([])

  const { error, loading } = useFirestoreSubscribe(
    {
      queryRefFn: () => {
        if (!workspaceId) {
          return
        }

        const baseQuery = getWorkspaceViewsQuery(workspaceId)
        const fetchQuery = queryConstraints
          ? query(baseQuery, ...queryConstraints)
          : baseQuery

        return fetchQuery.withConverter<View<Timestamp, DocumentReference>>(
          viewConverter
        )
      },
      queryObserver: (snapshot) => {
        const value = snapshot.docs.map((doc) => doc.data())
        setViews(value)
      },
    },
    [workspaceId]
  )

  return [views, loading, error]
}

export default useViewsSubscribe
