import { Base, getConverter } from './document'

export type BehaviorType =
  | 'visit'
  | 'ready'
  | 'play'
  | 'pause'
  | 'enterfullscreen'
  | 'exitfullscreen'
  | 'actionbuttonshown'
  | 'actionbuttonhidden'
  | 'actionbuttonclick'
  | 'actionformshown'
  | 'actionformsubmit'
  | 'chapterclicked'
  | 'tab_close'
  | 'tab_move'
  | 'video_watched_complete'

/**
 * Firestore Document Behavior Interface
 * @typeparam {T} set timestamp type parameter
 * @typeparam {U} set DocumentReference type parameter
 * */
export interface Behavior<T = any, U = any> extends Base<T, U> {
  uid: string
  type: 'campaign' | 'embed' | 'contact' | 'schedule' | 'webinar' | 'portal'
  workspaceId: string
  contentId?: string
  campaignId?: string
  scheduleId?: string
  webinarId?: string
  embedId?: string
  portalId?: string
  videoId?: string
  contactId?: string
  behaviorType: BehaviorType
  currentTime: number
}

export const behaviorConverter = getConverter<Behavior>()

export interface CampaignBehavior
  extends Omit<
    Behavior,
    | 'type'
    | 'contentId'
    | 'campaignId'
    | 'scheduleId'
    | 'webinarId'
    | 'embedId'
    | 'portalId'
    | 'videoId'
    | 'contactId'
  > {
  type: 'campaign'
  contentId: string
  campaignId: string
}

export interface EmbedBehavior
  extends Omit<
    Behavior,
    | 'type'
    | 'contentId'
    | 'campaignId'
    | 'scheduleId'
    | 'webinarId'
    | 'embedId'
    | 'portalId'
    | 'videoId'
    | 'contactId'
  > {
  type: 'embed'
  contentId: string
  embedId: string
}

export interface ContactBehavior
  extends Omit<
    Behavior,
    | 'type'
    | 'contentId'
    | 'campaignId'
    | 'scheduleId'
    | 'webinarId'
    | 'embedId'
    | 'portalId'
    | 'videoId'
    | 'contactId'
  > {
  type: 'contact'
  contentId: string
  contactId: string
}

export interface ScheduleBehavior
  extends Omit<
    Behavior,
    | 'type'
    | 'contentId'
    | 'campaignId'
    | 'scheduleId'
    | 'webinarId'
    | 'embedId'
    | 'portalId'
    | 'videoId'
    | 'contactId'
  > {
  type: 'schedule'
  contentId: string
  scheduleId: string
}

export interface WebinarBehavior
  extends Omit<
    Behavior,
    | 'type'
    | 'contentId'
    | 'campaignId'
    | 'scheduleId'
    | 'webinarId'
    | 'embedId'
    | 'portalId'
    | 'videoId'
    | 'contactId'
  > {
  type: 'webinar'
  webinarId: string
}

export interface PortalBehavior
  extends Omit<
    Behavior,
    | 'type'
    | 'contentId'
    | 'campaignId'
    | 'scheduleId'
    | 'webinarId'
    | 'embedId'
    | 'portalId'
    | 'videoId'
    | 'contactId'
  > {
  type: 'portal'
  contentId: string
  portalId: string
  videoId: string
}
