import {
  DocumentReference,
  doc,
  collection,
  Timestamp,
} from 'firebase/firestore'
import { Content, contentConverter } from 'vidbase-types/content'
import { db } from '@/services/firebase/client'
import crudGenerator from '@/services/firebase/client/firestore/crudGenerator'

export const getContentsRef = (workspaceId: string) => {
  const ref = collection(db, 'workspaces', workspaceId, 'contents')
  return ref
}

export const getContentRef = (workspaceId: string, contentId?: string) => {
  const collectionRef = getContentsRef(workspaceId)
  const ref = contentId ? doc(collectionRef, contentId) : doc(collectionRef)
  return ref
}

export const {
  get: getContent,
  getList: getContents,
  add: addContent,
  create: createContent,
  partialUpdate: partialUpdateContent,
  delete: deleteContent,
} = crudGenerator<
  Content<Timestamp, DocumentReference>,
  [workspaceId: string],
  [workspaceId: string, contentId: string]
>(getContentsRef, getContentRef, contentConverter)
