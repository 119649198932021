import {
  collectionGroup,
  where,
  query,
  QueryConstraint,
} from 'firebase/firestore'
import { db } from '@/services/firebase/client'

export const getViewsQuery = () => collectionGroup(db, 'views')

export const getWorkspaceViewsQuery = (workspaceId: string) => {
  const q = getViewsQuery()
  const queryConstraints: QueryConstraint[] = [
    where('workspaceId', '==', workspaceId),
  ]

  const getQuery = query(q, ...queryConstraints)
  return getQuery
}

export const getContentViewsQuery = (
  workspaceId: string,
  contentId: string
) => {
  const q = getViewsQuery()
  const queryConstraints: QueryConstraint[] = [
    where('workspaceId', '==', workspaceId),
    where('contentId', '==', contentId),
  ]

  const getQuery = query(q, ...queryConstraints)
  return getQuery
}
